<template>
  <a-modal
    id="tarce-page-video-modal"
    :visible="true"
    centered
    width="100%"
    wrap-class-name="full-modal inference-modal"
    destroy-on-close
    :title="null"
    :footer="null"
    :closable="false"
    :body-style="{ padding: '0px' }"
  >
    <div v-if="initLoading" class="d-flex h-100">
      <a-spin class="m-auto" size="large" />
    </div>
    <div
      v-else-if="!initLoading && isCycleNotFound"
      class="w-100 h-100 d-flex justify-content-center align-items-center"
      style="
        border: 1px solid #f5c6cb;
        border-radius: 8px;
        padding: 20px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      "
    >
      <div
        class="text-center"
        style="font-weight: bold; color: #1890ff; font-size: 40px"
      >
        Cycle Not Found
      </div>
    </div>
    <div v-else class="p-3 h-100 overflow-hidden d-flex flex-column">
      <a-row class="w-100" :gutter="[32, 0]">
        <a-col span="12">
          <a-typography-title :level="5">
            {{ selectedVideo?.fileName || '-' }}
          </a-typography-title>
        </a-col>
        <a-col
          span="12"
          class="d-flex align-items-center justify-content-end mb-2"
        >
          <a-space v-if="translationLanguage" class="">
            <a-typography-text class="bold">
              Select Language:
            </a-typography-text>
            <a-select
              :value="selectedLang"
              :options="languageOptions"
              class="w-20"
              size="small"
              @change="(value) => setSelectedLanguage(value)"
            />
          </a-space>
          <a-button
            type="primary"
            size="small"
            class="ml-2"
            @click="$router.push({ name: 'Home' })"
          >
            Go to Dashboard
          </a-button>
        </a-col>
      </a-row>
      <recorded-inference
        v-if="selectedVideo"
        :is-fetching-video-url="isFetchingVideoUrl"
        :video="selectedVideo"
        :steps-list="stepsList"
        :comments="comments"
        :navigate="true"
        @toggleCycleStatus="toggleCycleStatus"
        @updateVideo="updateVideo"
        @loading="(val) => (isFetchingVideoUrl = val)"
      />
    </div>
  </a-modal>
</template>
<script>
import RecordedInference from 'src/components/shared/Components/RecordedInference.vue';
import parseTaskList from 'src/components/shared/Helpers/parseTaskList';
import analyticsTrace from 'src/mixins/analyticsTrace';
import TaskRecordService from 'src/services/taskRecord';
import TelemetryService from 'src/services/telemetry';
import VideoService from 'src/services/videos';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: { RecordedInference },
  mixins: [analyticsTrace],
  inject: ['toast'],
  props: {
    fetchPresignedUrl: { type: Function, default: () => {} },
  },
  data() {
    return {
      initLoading: true,
      selectedVideo: null,
      isCycleNotFound: false,
      // inference
      isFetchingVideoUrl: false,
      stepsList: [],
      // comments
      comments: [],
      historyId: null,
    };
  },
  computed: {
    ...mapGetters([
      'organization',
      'selectedTask',
      'taskDetails',
      'selectedLang',
      'translationLanguage',
      'indexToStepsMapping',
      'negativeSteps',
      'optionalSteps',
      'taskProcesses',
    ]),
    languageOptions() {
      return [
        { value: 'English', label: 'ENGLISH' },
        {
          value: `${this.translationLanguage}`,
          label: `${this.translationLanguage?.toUpperCase()}`,
        },
      ];
    },
  },
  watch: {
    taskProcesses: {
      handler() {
        this.setIndexToStepsMapping();
        this.setStepsToIndexMapping();
        this.setStepToAttributesMapping();
        this.handleFetchRequiredData();
      },
      deep: true,
    },
    taskDetails(value) {
      this.stepsList = parseTaskList(value);
    },
    selectedLang(value) {
      this.setStepTranslations(value);
    },
  },
  created() {
    this.stepsList = parseTaskList(this.taskDetails);
    this.handleFetchRequiredData();
  },
  methods: {
    ...mapActions([
      'setSelectedLanguage',
      'setStepTranslations',
      'fetchTaskDetails',
      'setSelectedTask',
      'setIndexToStepsMapping',
      'setStepsToIndexMapping',
      'setStepToAttributesMapping',
      'setIsFetchingCycles',
    ]),

    async toggleCycleStatus({ video, isGoodCycle }) {
      const [error] = await TelemetryService.revertCycle(video.cycle_id, {
        is_good_cycle: isGoodCycle,
      });
      if (error) {
        this.toast.error('Unable to update the cycle status!');
        return;
      }
    },

    isNegativeOrOptionalStep(index) {
      return (
        this.negativeSteps?.includes(index) ||
        this.optionalSteps?.includes(index)
      );
    },

    async handleFetchRequiredData() {
      const { traceId } = this.$route.params;
      const { cycle_id, taskId } = this.$route.query;
      const query = { cycle_id, task_record_id: traceId };
      this.setSelectedTask(Number(taskId));
      await this.handleGetTraceRecordData(query);
      await this.fetchTaskDetails({
        taskId: Number(taskId),
        historyId: this.historyId,
      });
    },

    async handleGetTraceRecordData(query) {
      const [error, data] = await TelemetryService.getTraceCycleRecordData(
        query
      );

      if (error) {
        this.initLoading = false;
        this.isCycleNotFound = true;
        return;
      }
      if (data?.cycle?.history_id) {
        this.historyId = data.cycle.history_id;
      }
      let video = this.appendCycleDataInTraceRecord(data);
      video = this.appendMissedSteps(video);
      video = await this.handleGetVideoData(video);
      await this.fetchComments(video);
      this.selectedVideo = { ...video, cycleData: data.cycle };
      this.initLoading = false;
    },

    appendCycleDataInTraceRecord(data) {
      if (!data || !Object.keys('data')?.length) return;
      let video = {};
      const {
        cycle,
        task_record: { ...trace_data },
      } = data;

      video = {
        ...trace_data,
        cycle_time: trace_data.duration,
        cycle_id: cycle.id,
        total_inference_time: cycle.total_inference_time,
        total_missed_steps: cycle.missed_steps_to_display_len,
        missed_steps: cycle.missed_steps_to_display?.join(','),
      };
      return video;
    },

    async handleGetVideoData(video) {
      if (!video.fileURL || !video.per_frame_prediction_file_url) {
        this.isFetchingVideoUrl = true;
        const [video_url, preds_url] = await Promise.all([
          this.getFileUrl(video),
          this.getFileUrl(video, true),
        ]);

        const videoFPS = await this.getVideoFPS(video);
        this.isFetchingVideoUrl = false;

        if ([video_url, preds_url].includes('error')) {
          this.toast.error('Error occure while fetching url!');
          return;
        }

        return {
          ...video,
          fileURL: video_url,
          per_frame_prediction_file_url: preds_url,
          isVideoExists: true,
          fps: videoFPS,
        };
      }
      return { ...video };
    },

    async getFileUrl(video, isPredictionFile = false) {
      const { bucket, filePath } = this.getVideoS3Details(
        video,
        isPredictionFile
      );
      return await this.fetchPresignedUrl(bucket, filePath);
    },

    getVideoS3Details(video, isPredictionFile = false) {
      const { fileName, device_id } = video;
      const bucket = `${this.organization}-videos`;
      let filePath = `${device_id}/${this.selectedTask}/Processed/${fileName}`;
      if (isPredictionFile) filePath += '_preds.json';
      return { bucket, filePath };
    },

    getVideoFPS(video) {
      return new Promise(async (resolve) => {
        let payload = { id: video.id };
        const [error, res] = await TaskRecordService.getVideoMetaData(
          payload,
          false
        );

        if (error || (res && !Object.keys(res)?.length)) {
          console.log({ error });
          return resolve(30);
        }
        const { data } = res;
        let { fps } = data;
        return resolve(fps);
      });
    },

    async fetchComments(video) {
      let data = [];
      if (!video.entity_id) {
        data = [];
      } else if (video.comments?.length) {
        data = [...video.comments];
      } else {
        const comment_response = await VideoService.getCommentsForEntity(
          video.entity_id
        );
        data = [...comment_response];
      }
      if (!video.comments) {
        this.updateVideo({ updatedObj: { comments: data } });
      }
      this.comments = [...data];
    },

    updateVideo(params) {
      if (this.selectedVideo)
        this.selectedVideo = { ...this.selectedVideo, ...params.updatedObj };
    },
  },
};
</script>
