<template>
  <a-row :gutter="[0, 16]">
    <a-col span="24">
      <a-tabs
        v-model:activeKey="currentTab"
        style="height: 70vh"
        type="card"
        class="w-100"
        size="small"
      >
        <a-tab-pane key="1" tab="Telemetry Parameters">
          <telemetry-parameters />
        </a-tab-pane>
        <a-tab-pane key="2" tab="Training Parameters">
          <training-parameters />
        </a-tab-pane>
        <a-tab-pane key="3" tab="Negative Steps">
          <negative-steps />
        </a-tab-pane>
      </a-tabs>
    </a-col>

    <a-col span="24" class="d-flex">
      <a-space class="ml-auto">
        <a-button
          id="task-param-cancel-button"
          :disabled="isUpdatingTaskParams"
          @click="
            showModal({
              modalType: types.SET_SHOW_TASK_PARAMS_MODAL,
              value: false,
            })
          "
        >
          Cancel
        </a-button>
        <a-button
          id="task-param-save-button"
          type="primary"
          :loading="isUpdatingTaskParams"
          @click="updateTaskJsonParams"
        >
          {{ isUpdatingTaskParams ? 'Applying Changes...' : 'Update' }}
        </a-button>
      </a-space>
    </a-col>
  </a-row>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import types from 'src/store/mutation-types';
import { trainingParams } from './config';
import TelemetryParameters from './TelemetryParameters.vue';
import TrainingParameters from './TrainingParameters.vue';
import NegativeSteps from './NegativeSteps.vue';

export default {
  components: {
    TelemetryParameters,
    TrainingParameters,
    NegativeSteps,
  },
  inject: ['toast'],
  setup() {
    return { types };
  },
  data() {
    return {
      currentTab: '1',
    };
  },
  computed: {
    ...mapGetters([
      'isUpdatingTaskParams',
      'taskParameters',
      'subStepTimesJson',
      'taskJson',
      'stepsToIndexMapping',
    ]),
  },

  methods: {
    ...mapActions(['showModal', 'updateTaskJson']),

    isValidPercentage(val) {
      if (!val) return true;
      const percentageAsFloat = parseFloat(val);
      return percentageAsFloat > 0 && percentageAsFloat <= 1;
    },

    isValidTemporalSmoothnessValue(smoothnessValue) {
      return smoothnessValue > 0 && smoothnessValue < 21;
    },

    isValidAddBackgroundTimetoStepTimeValue(add_background_time_to_step_time) {
      return typeof add_background_time_to_step_time === 'boolean';
    },

    isValidTrainingParams(trainingParamsObj) {
      const inputs = trainingParams.filter((param) => param.type === 'input');
      let notValidObj = null;
      for (let i = 0; i < inputs.length; i++) {
        const inputParam = inputs[i];
        const value = trainingParamsObj[inputParam.key];
        if (value && (value < inputParam.min || value > inputParam.max)) {
          notValidObj = {
            key: inputParam.title,
            min: inputParam.min,
            max: inputParam.max,
          };
          break;
        }
      }
      return notValidObj;
    },

    isValidTaskParameters() {
      const { telemetryParams, trainingParams } = this.taskParameters;
      const {
        min_percentage_of_steps_required_to_end_cycle,
        temporal_smoothness,
        maximum_cycle_time,
        add_background_time_to_step_time
      } = telemetryParams;

      if (!this.isValidTemporalSmoothnessValue(temporal_smoothness)) {
        this.toast.info(
          'Please enter correct temporal smoothness value! (Valid range is between 1 and 20)'
        );
        return false;
      }

      if (
        !this.isValidPercentage(min_percentage_of_steps_required_to_end_cycle)
      ) {
        this.toast.info(
          'Please enter correct percentage value! (Valid range is between 0.00% and 1.00%)'
        );
        return false;
      }

      if (!maximum_cycle_time) {
        this.toast.info('Maximum cycle time value should be defined.');
        return false;
      }

      if (!this.isValidAddBackgroundTimetoStepTimeValue(add_background_time_to_step_time)) {
        this.toast.info('Add background time to step time value should be valid.');
        return false;
      }
      
      const trainingParamsRes = this.isValidTrainingParams(trainingParams);
      if (trainingParamsRes) {
        const { min, max, key } = trainingParamsRes;
        let str = `The value of ${key}  can not be lower than ${min}`;
        str += max ? ` or greater than ${max}` : '';
        this.toast.info(str);
        return false;
      }
      return true;
    },

    sum(obj) {
      return Object.keys(obj).reduce(
        (sum, key) => sum + parseFloat(obj[key] || 0),
        0
      );
    },

    getCycleTimeMultiplier(taskParam) {
      const { telemetryParams } = this.taskParameters;
      const sum = this.sum(this.subStepTimesJson);
      return sum ? (telemetryParams[taskParam] / sum).toFixed(4) : 0;
    },

    getUpdatedTaskJson(taskParameters) {
      const { telemetryParams, trainingParams } = taskParameters;
      const { maximum_cycle_time, minimum_cycle_time, ...resTelemetryParams } =
        telemetryParams;
      return {
        ...this.taskJson,
        cycle_calculation_params: { ...resTelemetryParams },
        training_params: { ...trainingParams },
      };
    },

    processTaskParameters(obj) {
      const stringParams = ['model_choice'];
      Object.keys(obj).forEach((key) => {
        const paramValue = obj[key];
        if (paramValue !== null && typeof paramValue == 'object') {
          obj[key] = JSON.stringify(paramValue);
        } else if (stringParams.includes(key)) {
          obj[key] = paramValue;
        } else if (paramValue !== null && typeof paramValue == 'boolean') {
          obj[key] = Boolean(paramValue);
        } else {
          obj[key] = paramValue ? Number(paramValue) : null;
        }
      });
      return obj;
    },

    getUpdateTaskParametersPayload() {
      const { taskParameters } = this;
      const { telemetryParams, trainingParams } = taskParameters;
      telemetryParams['maximum_cycle_time_multiplier'] =
        this.getCycleTimeMultiplier('maximum_cycle_time');
      telemetryParams['minimum_cycle_time_multiplier'] =
        this.getCycleTimeMultiplier('minimum_cycle_time');

      let obj = {
        ...telemetryParams,
        ...trainingParams,
      };

      const updatedTaskParams = this.processTaskParameters(obj);
      const updatedTaskJson = this.getUpdatedTaskJson(taskParameters);
      const substepTimesJson = this.getSubStepTimesJson();
      if (substepTimesJson === 'error') return;

      const payload = {
        taskParams: updatedTaskParams,
        taskJson: updatedTaskJson,
        substepTimes: substepTimesJson,
      };
      return payload;
    },

    async updateTaskJsonParams() {
      const validation = this.isValidTaskParameters();
      if (!validation) return;
      const payload = this.getUpdateTaskParametersPayload();
      console.log('payload ->', payload);
      if (payload) await this.updateTaskJson(payload);
    },

    getSubStepTimesJson() {
      const values = Object.values(this.subStepTimesJson);
      if (!values?.length || values?.every((value) => value == '')) return null;

      let json = {};
      if (values.length) {
        const steps = Object.entries(this.stepsToIndexMapping);
        steps.forEach(([step, index]) => {
          json[index] = this.subStepTimesJson[step]
            ? Number(this.subStepTimesJson[step])
            : 0;
        });
        if (!this.subStepTimesJson['background']) {
          this.toast.info('please add background time in Substep Times');
          return 'error';
        }
        json[steps.length] = Number(this.subStepTimesJson['background']);
        // json = JSON.stringify(json);
      }
      return json;
    },
  },
};
</script>
<style>
.ant-tabs-content {
  height: 100% !important;
  overflow-y: auto !important;
}
</style>
