export const chartsNames = {
  StepAverageTime: 'Average Step Duration',
  CycleTimeDistribution: 'Cycle Time Distribution',
  MissedSteps: 'Missed Steps',
  NonRelatedActivity: 'Non-Value Add Time By Session',
  TimeDistribution: 'Individual Cycle Time',
  DateWiseSessions: 'Cycle Count By Session',
  DeviceWiseCycleTimeDistribution: 'Individual Cycle Timestamps By Date'
};

export const screenSizes = ['colXS', 'colSM', 'colMD', 'colLG', 'colXL'];

export const chartsIndicesForFirstDateRange = [0, 1, 2, 3, 4, 5, 6];
export const chartsIndicesForFirstDateRangeWithComparison = [
  0,
  2,
  4,
  6,
  8,
  10,
  12
];

export const chartsIndicesForSecondDateRange = [1, 3, 5, 7, 9, 11, 13];

export const charts = [
  {
    title: chartsNames.StepAverageTime,
    alignTitle: 'start',
    data: '',
    labels: '',
    stepsAvgTime: '',
    stepsStdDev: '',
    xlabelString: 'Steps',
    ylabelString: 'Mean time per step (seconds)',
    description:
      'Arithmetic means and standard deviations of step times, as well as standard (or expected) step times (if) defined by the user.',
    colXS: 24,
    colSM: 24,
    colMD: 24,
    colLG: 24,
    colXL: 12,
    visible: true
  },
  {
    title: chartsNames.CycleTimeDistribution,
    data: '',
    alignTitle: 'start',
    labels: '',
    xlabelString: 'Cycle Time (seconds)',
    ylabelString: 'Number of Cycles',
    plotLine: '',
    cycleTimeOutliers: '',
    description:
      'Histogram showing number of cycles versus cycle times, as well as the standard (or expected) cycle time (if) defined by the user. Click on a bar to redirect to Trace videos corresponding to it, if available.',
    colXS: 24,
    colSM: 24,
    colMD: 24,
    colLG: 24,
    colXL: 12,
    visible: true
  },

  {
    title: chartsNames.MissedSteps,
    data: '',
    alignTitle: 'start',
    labels: '',
    interventions: '',
    xlabelString: 'Step Name and Interventions',
    ylabelString: 'Number of Cycles',
    description: 'Number of times a step is missed by operator(s).',
    colXS: 24,
    colSM: 24,
    colMD: 24,
    colLG: 24,
    colXL: 12,
    visible: true
  },

  {
    title: chartsNames.NonRelatedActivity,
    data: '',
    alignTitle: 'start',
    labels: '',
    xlabelString: 'Date',
    ylabelString: 'Average background time per cycle (second)',
    description: 'Average Non-Value Add Time observed by work session.',
    colXS: 24,
    colSM: 24,
    colMD: 24,
    colLG: 24,
    colXL: 12,
    visible: true
  },
  {
    title: chartsNames.TimeDistribution,
    data: '',
    alignTitle: 'center',
    labels: '',
    xlabelString: 'Date',
    ylabelString: 'Cycle Times',
    description:
      'Cycle Time for each cycle observed. Click on a dot to see the corresponding Trace video, if available.',
    colXS: 24,
    colSM: 24,
    colMD: 24,
    colLG: 24,
    colXL: 24,
    visible: true
  },
  {
    title: chartsNames.DateWiseSessions,
    data: '',
    alignTitle: 'center',
    labels: '',
    xlabelString: 'Sessions',
    ylabelString: 'Cycle Count',
    description:
      'Number of cycles performed in a session, as well as a bar depicting standard/expected number of cycles based on standard cycle time (if) defined by user.',
    colXS: 24,
    colSM: 24,
    colMD: 24,
    colLG: 24,
    colXL: 24,
    visible: true
  },

  {
    title: chartsNames.DeviceWiseCycleTimeDistribution,
    data: '',
    alignTitle: 'center',
    labels: '',
    xlabelString: 'Time',
    ylabelString: 'Date',
    description:
      "Each stick represents an individual cycle's start time. Hover on a stick to see that cycle's details.",
    colXS: 24,
    colSM: 24,
    colMD: 24,
    colLG: 24,
    colXL: 24,
    visible: true
  }
];

export const cardsKeys = [
  'number_of_cycles',
  'average_cycle_time',
  'total_cycles_time',
  'average_value_add_percentage',
  'takt_grade',
  'takt_percentage'
];
export const cardIndicesForFirstDateRange = [0, 1, 2, 3, 4, 5];
export const cardIndicesForFirstDateRangeWithComparison = [0, 2, 4, 6, 8, 10];
export const cardIndicesForSecondDateRange = [1, 3, 5, 7, 9, 11];

export const cards = [
  {
    label: 'Total Cycles',
    key: 'number_of_cycles',
    value: '0',
    percent: '-',
    description: 'Total number of cycles performed in selected duration.',
    colXS: 24,
    colSM: 24,
    colMD: 24,
    colLG: 12,
    colXL: 12,
    goodCycles: 0,
    badCycles: 0,
    percentile: 0
  },
  {
    label: 'Average Cycle Time',
    key: 'average_cycle_time',
    value: '0',
    percent: '-',
    description:
      'Arithmetic mean of cycle times performed in selected duration.',
    colXS: 24,
    colSM: 24,
    colMD: 24,
    colLG: 12,
    colXL: 12
  },
  {
    label: 'Total Work Time',
    key: 'total_cycles_time',
    value: '0',
    percent: '-',
    description: 'Total time spent on work in the selected duration.',
    colXS: 24,
    colSM: 24,
    colMD: 24,
    colLG: 12,
    colXL: 12
  },

  {
    label: 'Average Value Add Percentage',
    key: 'average_value_add_percentage',
    value: '0',
    percent: '-',
    description:
      'Average of total time spent on defined steps divided by total time spent in a cycle.',
    colXS: 24,
    colSM: 24,
    colMD: 24,
    colLG: 12,
    colXL: 12
  },
  {
    label: 'Takt Grade',
    key: 'takt_grade',
    value: '-',
    percent: '-',
    description:
      'Letter Grade based on Takt Percentage (A+ = above 80%, A = 70-80%, B = 60-70%, C = 50-60%, D = 40-50%, E = 30-40%, F = under 30%)',
    colXS: 24,
    colSM: 24,
    colMD: 24,
    colLG: 12,
    colXL: 12
  },
  {
    label: 'Takt Percentage',
    key: 'takt_percentage',
    value: '-',
    percent: '-',
    description:
      'Number of cycles actually performed as a percentage of number of cycles that should have been performed based on standard cycle time.',
    colXS: 24,
    colSM: 24,
    colMD: 24,
    colLG: 12,
    colXL: 12
  }
];

export const heatMap = {
  title: 'Wandb History',
  data: '',
  labels: '',
  xlabelString: 'Predicted Values',
  ylabelString: 'Actual values',
  col: 12
};
