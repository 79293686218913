<template>
  <a-modal
    id="deploy-model-schedule-task-model"
    v-model:visible="showSchedule"
    title="Schedule Task"
    ok-text="Schedule"
    :mask-closable="false"
    :ok-button-props="{ id: 'dm-schedule-task-ok-btn' }"
    :cancel-button-props="{
      id: 'dm-schedule-task-cancel-btn',
      style: { marginLeft: 'auto' },
    }"
    :confirm-loading="isScheduleTaskLoading"
    @ok="handleScheduleTask"
  >
    <template #closeIcon>
      <close-outlined id="trimming-modal-close-btn" />
    </template>
    <a-spin v-if="isScheduling" />
    <div v-else>
      <a-select
        id="dm-schedule-task-days-select"
        v-model:value="selectedDays"
        class="w-100"
        mode="multiple"
        placeholder="Select day(s)"
        :options="daysOptions"
      />
      <a-time-range-picker
        id="dm-time-range-input"
        v-model:value="startEndTime"
        class="w-100 mt-3"
        format="HH:mm"
        style="width: 400px"
      />
    </div>
  </a-modal>

  <a-table
    id="dep-model-active-devices-table"
    :columns="columns"
    :data-source="activeDevices"
    :loading="isLoadingDevices"
    :scroll="{ x: true }"
    :pagination="{ position: ['bottomCenter'] }"
  >
    <template #title>
      <a-typography-title :level="5">
        Device(s) Active: &nbsp;
        <a-tag id="record-active-devices-count" color="blue">
          {{ activeDevicesCount }}
        </a-tag>
      </a-typography-title>
    </template>

    <template #emptyText>
      <div
        id="dep-model-no-devices"
        style="height: 40vh"
        class="d-flex flex-column align-items-center justify-content-center"
      >
        <laptop-outlined style="fontsize: 40px" />
        <span class="mt-2">No Active Device Found</span>
      </div>
    </template>

    <template :id="'dep-model' + title + '-hdcell'" #headerCell="{ title }">
      <span class="text-center">
        {{ title }}
      </span>
    </template>

    <template #bodyCell="{ record, column, index }">
      <div
        v-if="column.dataIndex === 'deviceId'"
        :id="'dep-model-deviceId-' + index"
        class="text-center"
      >
        {{ record.display_name || record.Serial_number }}
      </div>

      <div
        v-if="column.dataIndex === 'status'"
        class="text-center d-flex align-items-center"
      >
        <div
          v-if="
            record?.downloading ||
            record?.uploading ||
            record.isInferenceRunning
          "
          style="width: 100%"
          class="d-flex justify-content-center align-items-center"
        >
          <a-tag
            v-if="
              record.isInferenceRunning ||
              (record.is_shift_managed && record.inference)
            "
            :id="'dm-status-inference-' + index"
            color="processing"
          >
            Running Guidance
          </a-tag>
          <a-tag
            style="display: flex; justify-content: center; align-items: center"
            class="mt-1 text-center"
            v-if="
              record?.uploading?.progress > 0 ||
              record?.downloading?.progress > 0
            "
          >
            <template #icon>
              <span
                v-if="
                  record?.uploading?.progress > 0 ||
                  record?.downloading?.progress > 0
                "
              >
                {{
                  record?.uploading
                    ? record?.uploading?.progress + '%'
                    : record?.downloading?.progress + '%'
                }}
              </span>
            </template>
            &nbsp;{{ record?.uploading ? 'Uploading' : 'Downloading' }}
          </a-tag>
          <a-tag
            style="display: flex; justify-content: center; align-items: center"
            class="mt-1 text-center"
            v-if="
              record?.uploading?.progress > 0 ||
              record?.downloading?.progress > 0
            "
          >
            <template #icon>
              <span>
                {{
                  record?.uploading
                    ? record?.uploading?.name
                    : record?.downloading?.name
                }}
              </span>
            </template>
          </a-tag>
        </div>
        <!-- Inference -->

        <!-- Uploading -->
        <!-- <div v-else-if="record?.uploading">
          <a-tag
            :id="'dep-device-' + index + '-uploading-status'"
            color="processing"
          >
            <template #icon>
              <cloud-upload-outlined />
            </template>
            Uploading
          </a-tag>
          <a-tag v-if="record?.uploading?.name" color="success">
            {{ record?.uploading?.name }}
          </a-tag>
          <a-progress
            :id="'dep-device-' + index + '-uploading-progress'"
            type="circle"
            :percent="record?.uploading?.progress"
            :width="40"
          />
        </div> -->

        <!-- Downloading -->
        <!-- <div v-else-if="record?.downloading">
          <a-tag
            :id="'dm-status-downloading-button-' + index"
            color="processing"
          >
            <template #icon>
              <cloud-upload-outlined />
            </template>
            Downloading
          </a-tag>
          <a-tag :id="'dm-status-downloaded-button-' + index" color="success">
            {{ record?.downloading.name }}
          </a-tag>

          <a-progress
            :id="'dm-download-progress-button-' + index"
            type="circle"
            :percent="record?.downloading?.progress"
            :width="40"
          />
        </div> -->

        <div
          v-else
          style="width: 100%"
          class="d-flex justify-content-center align-items-center"
        >
          <a-tag :id="'dep-model-status-none-' + index"> None </a-tag>
        </div>
      </div>

      <!-- Current Task -->
      <div
        v-if="column.dataIndex === 'task'"
        :id="'dep-model-task-' + index"
        class="text-center"
      >
        <a-select
          :id="'dep-model-select-device-task-' + index"
          :ref="(el) => (taskInputRefs[record.Serial_number] = el)"
          show-search
          class="w-100"
          :value="getTaskValue(record.Task)"
          placeholder="Select Operation"
          :options="taskOptions"
          :loading="isLoadingTasks"
          :disabled="
            record?.record_shift ||
            record?.isInferenceRunning ||
            record?.recordInference
          "
          :filter-option="filterOption"
          size="small"
          @change="(val) => handleChangeTask(record, val)"
        />
      </div>

      <div v-if="column.dataIndex === 'action'" class="text-center">
        <a-button
          :id="'dep-model-task-record-button-' + index"
          :disabled="!record.Task"
          block
          :loading="isInferenceBtnLoading[record.id]"
          type="primary"
          @click="handleInferenceRunning(record)"
        >
          {{ record?.isInferenceRunning ? 'Stop' : 'Start' }}
          Task
        </a-button>

        <a-button
          :id="'dep-model-task-schedule-button-' + index"
          class="my-1"
          type="primary"
          :loading="isScheduleModalBtnLoading[record.id]"
          block
          @click="handleScheduleModal(record, 'inference')"
        >
          Schedule Task
        </a-button>

        <a-space
          :id="'dep-model-trace-switch-' + index"
          class="clickable"
          @click="handleTraceSwitch(record)"
        >
          <a-switch
            :checked="record?.recordInference"
            :loading="isTraceLoading[record.id]"
          />
          <span>Trace</span>
        </a-space>
      </div>
    </template>
  </a-table>
</template>
<script>
import {
  CloseOutlined,
  CloudUploadOutlined,
  LaptopOutlined,
} from '@ant-design/icons-vue';
import columns from 'src/config/columns-config.js';
import daysOptions from 'src/config/days-config.js';
import spaceMixin from 'src/mixins/handleSpace';
import { inject } from 'vue';
import { useActiveDevices } from '../RecordData/composables/useActiveDevices';

export default {
  components: {
    LaptopOutlined,
    CloudUploadOutlined,
    CloseOutlined,
  },
  mixins: [spaceMixin],
  setup() {
    const {
      isLoadingTasks,
      activeDevicesList,
      activeDevices,
      showScheduleModal,
      scheduleInference,
      isScheduling,
      selectedDays,
      startEndTime,
      isLoadingDevices,
      activeDevicesCount,
      updateDeviceSettings,
      handleRecording,
      showSchedule,
      taskInputRefs,
      taskOptions,
      handleChangeTask,
      handleInference,
      updateDeviceSettingsAndActiveDevices,
    } = useActiveDevices();
    const toast = inject('toast');

    async function handleTrace(record) {
      const { recordInference, record_shift } = record;
      if (record_shift) {
        record_shift &&
          toast.info(`Cannot turn on Trace when shift is being recorded!`);
        return;
      }

      if (recordInference || record_shift) {
        toast.info(`Cannot turn off Trace when shift is on trace!`);
        return;
      }
      await updateDeviceSettingsAndActiveDevices({
        ...record,
        key: 'recordInference',
        value: !recordInference,
      });
    }
    function getTaskValue(task) {
      if (task && typeof task === 'object' && task.id) {
        return task.id;
      } else {
        return task;
      }
    }

    return {
      columns,
      isLoadingTasks,
      handleChangeTask,
      activeDevicesList,
      activeDevices,
      showScheduleModal,
      scheduleInference,
      isScheduling,
      selectedDays,
      startEndTime,
      showSchedule,
      isLoadingDevices,
      activeDevicesCount,
      taskInputRefs,
      updateDeviceSettings,
      handleRecording,
      daysOptions,
      taskOptions,
      handleTrace,
      handleInference,
      getTaskValue,
    };
  },
  data() {
    return {
      isScheduleTaskLoading: false,
      isInferenceBtnLoading: {},
      isScheduleModalBtnLoading: {},
      isTraceLoading: {},
    };
  },
  methods: {
    async handleScheduleTask() {
      this.isScheduleTaskLoading = true;
      await this.scheduleInference();
      this.isScheduleTaskLoading = false;
    },

    async handleInferenceRunning(record) {
      const index = record.id;
      this.isInferenceBtnLoading[index] = true;
      this.isTraceLoading[index] = true;
      await this.handleInference(record);
      this.isInferenceBtnLoading[index] = false;
      this.isTraceLoading[index] = false;
    },

    handleScheduleModal(record, action) {
      const index = record.id;
      this.isScheduleModalBtnLoading[index] = true;
      this.showScheduleModal(record, action);
      this.isScheduleModalBtnLoading[index] = false;
    },

    async handleTraceSwitch(record) {
      const index = record.id;
      this.isTraceLoading[index] = true;
      await this.handleTrace(record);
      this.isTraceLoading[index] = false;
    },
  },
};
</script>
<style scoped></style>
