<template>
  <div class="w-100 h-100 d-flex flex-column">
    <a-list
      id="trace-video-comments-list"
      :data-source="commentsList"
      item-layout="horizontal"
      size="small"
      :style="{ height: '40vh', overflow: 'auto', flexGrow: 1 }"
      :loading="isFetchingVideoUrl"
    >
      <template #renderItem="{ item, index: commentIndex }">
        <a-list-item :id="'comment-' + commentIndex">
          <a-comment :author="item.user_id.username">
            <template #content>
              <div v-html="item.comment"></div>
            </template>
            <template v-if="isAssociateUserComment(item.user_id)" #actions>
              <delete-outlined
                :id="'delete-comment-btn-' + commentIndex"
                class="text-danger"
                @click="deleteComment(item)"
              />
            </template>
          </a-comment>
        </a-list-item>
      </template>
    </a-list>
    <a-comment>
      <template #content>
        <CommentArea
          :key="commentAreaKey"
          @createNewComment="handleNewComment"
        />
      </template>
    </a-comment>
  </div>
</template>
<script>
import { DeleteOutlined } from '@ant-design/icons-vue';
import { mapActions, mapGetters } from 'vuex';
import VideoService from '../../../../services/videos';
import CommentArea from './CommentArea.vue';

export default {
  components: {
    DeleteOutlined,
    CommentArea,
  },
  props: {
    comments: { type: Array, default: () => [] },
    selectedVideo: { type: Object, default: () => ({}) },
    isMinimized: { type: Boolean, default: false },
    isFetchingVideoUrl: { type: Boolean, default: false },
  },
  emits: ['updateVideo', 'updateVideoListCount'],
  setup() {
    const userId = localStorage.getItem('id');
    const userName = localStorage.getItem('name');
    return { userId, userName };
  },
  data() {
    return {
      addingComment: false,
      record: null,
      commentsList: [],
      newComment: '',
      commentMentions: [],
      commentAreaKey: 0, // Add a key to force re-render
    };
  },

  computed: {
    ...mapGetters(['user', 'organization', 'idToEntityIdMapping']),
  },

  watch: {
    selectedVideo(value) {
      if (value) this.record = value;
    },
    comments: {
      deep: true,
      handler(list) {
        this.commentsList = list;
      },
    },

    record: {
      immediate: true,
      deep: true,
      handler(record) {
        if (record) {
          if (record?.entity_id) {
            this.syncIdToEntityIdMapping({
              key: record.id,
              value: record?.entity_id,
            });
          }
          // this.idToEntityIdMapping[record.id] = record?.entity_id;
          this.fetchComments();
        }
      },
    },
  },

  mounted() {
    this.getOrganizationUsers();
  },

  beforeMount() {
    this.commentsList = this.comments;
    this.record = this.selectedVideo;
  },

  methods: {
    ...mapActions(['syncIdToEntityIdMapping', 'getOrganizationUsers']),

    getEntityId() {
      return this.idToEntityIdMapping[this.record.id];
    },

    async fetchComments() {
      let entityId = this.getEntityId();
      if (entityId) {
        const comment_response = await VideoService.getCommentsForEntity(
          entityId
        );
        this.commentsList = comment_response;
      }
    },

    handleNewComment({ comment, mentions }) {
      this.newComment = comment;
      this.commentMentions = mentions;
      this.handleAddNewComment();
    },

    async handleAddNewComment() {
      let entityId = this.getEntityId();
      if (!this.newComment.length) {
        this.addingComment = false;
        this.newComment = '';
        return;
      }
      this.addingComment = true;
      // add entity if not present
      if (!entityId) {
        const res = await this.addNewEntity();
        const temp = { ...this.record };
        temp['entity_id'] = res.id;
        this.syncIdToEntityIdMapping({
          key: this.record.id,
          value: res.id,
        });
        this.record = temp;

        const { entity_id, no_of_comments } = this.record;
        await this.updateTaskRecord(
          {
            entity_id: entity_id,
            no_of_comments: no_of_comments + 1,
            comments: this.commentsList,
          },
          this.record
        );
      }
      // create comment
      const comment_res = await this.createComment();
      // await VideoService.increaseCommentCount(this.record);
      comment_res['user_id'] = { id: comment_res.user_id, username: this.user };
      this.commentsList = [...this.commentsList, comment_res];
      this.$emit(
        'updateVideoListCount',
        this.record.id,
        this.commentsList.length
      );
      // update task record

      // if (!this.isMinimized) this.updateCommentCounts(1);
      this.updateCommentCounts(1);
      this.addingComment = false;
      this.newComment = '';
      // Update the key to trigger re-render
      this.commentAreaKey += 1;
    },

    async addNewEntity() {
      const payload = {
        name: this.record.fileName,
        organization: localStorage.getItem('organization'),
        // new attributes
      };
      return await VideoService.createEntityForVideo(payload);
    },

    async createComment() {
      let entityId = this.getEntityId();
      const payload = {
        comment: this.newComment,
        entity_id: entityId,
        user_id: this.userId,
        mentions: this.commentMentions,
      };
      return await VideoService.addNewComment(payload);
    },

    async updateTaskRecord(updatedData, videoToUpdate) {
      // update video item
      // this.$emit('updateVideo', {
      //   videoName: videoToUpdate.fileName,
      //   updatedObj: { ...updatedData },
      // });

      // delete updatedData?.comments;
      return await VideoService.updateTaskRecord(videoToUpdate.id, updatedData);
    },

    async deleteComment(comment) {
      const [error] = await VideoService.deleteComment(comment.id);
      if (error) {
        console.log({ error });
        return;
      }
      this.commentsList = this.commentsList.filter((t) => t.id !== comment.id);

      this.$emit(
        'updateVideoListCount',
        this.record.id,
        this.commentsList.length
      );
      // await VideoService.decreaseCommentCount(this.record);
      const count = this.record.no_of_comments - 1;
      // update task record
      await this.updateTaskRecord(
        { no_of_comments: count <= 0 ? 0 : count, comments: this.commentsList },
        this.record
      );
      // if (!this.isMinimized) this.updateCommentCounts(-1);
      this.updateCommentCounts(-1);
    },

    isAssociateUserComment(user_data) {
      return user_data.id == this.userId && user_data.username == this.userName
        ? true
        : false;
    },

    updateCommentCounts(increment) {
      const count = this.record.no_of_comments + increment;
      this.record = {
        ...this.record,
        no_of_comments: count <= 0 ? 0 : count,
      };
    },
  },
};
</script>
<style>
.ant-comment .ant-comment-inner {
  padding: 4px !important;
}

.ant-comment-actions {
  margin-top: 6px;
}
</style>
