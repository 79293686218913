import { getAuthConfig, getConfig } from './config';
import ApiHelper from './index';

const config = getConfig();
const authConfig = getAuthConfig();

async function signIn(payload) {
  return await ApiHelper.post('account/login_v2', payload, true, config);
}

async function signUp(payload) {
  return await ApiHelper.post(
    'account/register',
    payload,
    true,
    getAuthConfig()
  );
}

async function resendOtp() {
  return await ApiHelper.get('account/resend_otp/', true, getAuthConfig());
}

async function resendVerificationEmail(payload) {
  return await ApiHelper.post(
    'account/resend-verification-email',
    payload,
    true,
    config
  );
}

async function sendVerificationEmailAdmin(payload) {
  return await ApiHelper.post(
    'account/admin-verification-email',
    payload,
    true,
    getAuthConfig()
  );
}

async function verifyEmail(token, spinner = true) {
  return await ApiHelper.patch(
    `account/verify-email/${token}`,
    _,
    spinner,
    config
  );
}

async function setOTPMethod(payload) {
  return await ApiHelper.patch(
    `account/otp_receive_mode/`,
    payload,
    true,
    getAuthConfig()
  );
}

async function sendOTP() {
  return await ApiHelper.get(`account/send_otp_code/`, true, getAuthConfig());
}

async function verifyOTP(payload) {
  return await ApiHelper.post(
    `account/verify_otp_code/`,
    payload,
    true,
    getAuthConfig()
  );
}
async function getPermissions() {
  return await ApiHelper.get(
    'account/get-permissions/',
    _,
    true,
    getAuthConfig()
  );
}

async function acceptPolicy(email, payload) {
  return await ApiHelper.patch(
    `account/update-delete/${email}/`,
    payload,
    false,
    config
  );
}
async function azureSignIn(payload) {
  return await ApiHelper.post('account/azure-signin/', payload, true, config);
}

async function azureRefreshToken(payload) {
  return await ApiHelper.post(
    'account/refresh-azure-token/',
    payload,
    false,
    getAuthConfig()
  );
}

async function refreshJWTToken(payload) {
  return await ApiHelper.post(
    'account/token/refresh/',
    payload,
    false,
    getAuthConfig()
  );
}
async function azureSignUp(payload) {
  return await ApiHelper.post('account/azure-signup/', payload, true, config);
}

async function logout() {
  return await ApiHelper.patch('account/logout/', _, true, getAuthConfig());
}

async function forgotPassword(payload) {
  return await ApiHelper.patch(
    `account/forgot_password/`,
    payload,
    false,
    config
  );
}

async function changePassword(payload) {
  return await ApiHelper.patch(
    `account/reset_password/`,
    payload,
    false,
    config
  );
}

async function RequestChangePassword(payload) {
  return await ApiHelper.patch(
    `account/request_change_password/`,
    payload,
    false,
    getAuthConfig()
  );
}

async function RequestChangeEmail(payload) {
  return await ApiHelper.post(
    `account/request_change_email/`,
    payload,
    false,
    getAuthConfig()
  );
}

async function VerifyEmailChange(payload) {
  return await ApiHelper.patch(
    `account/verify-email-change/`,
    payload,
    false,
    getAuthConfig()
  );
}

async function UpdateWorkStationUserPassword(payload) {
  return await ApiHelper.patch(
    `account/reset_workstation_user_password/`,
    payload,
    false,
    getAuthConfig()
  );
}
async function AddOrgAdmin(payload) {
  return await ApiHelper.post(
    `account/register`,
    payload,
    false,
    getAuthConfig()
  );
}

async function RegisterAWSUser(payload) {
  try {
    const [error, res] = await ApiHelper.post(
      'account/register_for_aws_subscription',
      payload,
      true,
      getConfig()
    );

    if (error) {
      const errorData =
        error.response?.data?.error || 'An unknown error occurred';
      return [errorData];
    }
    const data = res.response;

    return [null, data];
  } catch (error) {
    const errorData =
      error.response?.data?.error || 'An unknown error occurred';
    return [errorData];
  }
}

async function RegisterNewAWSMarketplaceUser(payload) {
  try {
    const hostURL =
      'https://h0jzksr8x6.execute-api.us-east-1.amazonaws.com/Prod/';
    // payload.route = '/firebase_topic_subscription';
    const { data } = await axios.post(`${hostUrl}subscriber`, payload, {
      headers: {
        'Content-Type': 'application\\json',
        // "Access-Control-Allow-Origin" : "*",
        // "Accept": "*/*",
        // "Connection" : "keep-alive",
        // "Access-Control-Allow-Methods": "GET, POST, DELETE, PUT"
      },
    });
    return [null, data];
  } catch (error) {
    return [error];
  }
}

async function FetchOrganizations() {
  return await ApiHelper.get(
    `account/linked_organizations/`,
    false,
    null,
    getAuthConfig()
  );
}

async function SwitchOrganizationByUser(payload) {
  return await ApiHelper.patch(
    `account/switch_user_organization`,
    payload,
    false
  );
}

async function AddUserToMultipleOrgs(payload) {
  return await ApiHelper.post(
    `account/linked_organizations`,
    payload,
    false,
    getAuthConfig()
  );
}

async function GetUserLinkedOrgByUserId(params) {
  return await ApiHelper.get(
    `account/linked_organizations`,
    false,
    params,
    getAuthConfig()
  );
}

async function GetOrganizationUsers(organization, spinner = false) {
  return await ApiHelper.get(
    `account/linked_users_list/?organization=${organization}`,
    spinner
  );
}

async function RemoveUserFromMultipleOrgs(params) {
  return await ApiHelper.remove(
    `account/delete_linked_organizations`,
    false,
    params,
    getAuthConfig()
  );
}

export default {
  signIn,
  signUp,
  acceptPolicy,
  resendVerificationEmail,
  sendVerificationEmailAdmin,
  resendOtp,
  verifyEmail,
  setOTPMethod,
  verifyOTP,
  logout,
  RequestChangePassword,
  forgotPassword,
  changePassword,
  RequestChangeEmail,
  VerifyEmailChange,
  sendOTP,
  getPermissions,
  UpdateWorkStationUserPassword,
  azureSignIn,
  azureRefreshToken,
  azureSignUp,
  AddOrgAdmin,
  refreshJWTToken,
  RegisterAWSUser,
  RegisterNewAWSMarketplaceUser,
  FetchOrganizations,
  AddUserToMultipleOrgs,
  RemoveUserFromMultipleOrgs,
  GetUserLinkedOrgByUserId,
  SwitchOrganizationByUser,
  GetOrganizationUsers,
};
