<template>
  <a-card>
    <a-button @click="addNewStep" :disabled="!canAddNewStep">
      Add New Step
    </a-button>

    <div class="mt-5">
      <!-- Render NegativeStepCard for each step object -->
      <div v-if="!loading">
        <NegativeStepCard
          v-for="(step, index) in steps"
          :key="step.id"
          :onDelete="removeStep"
          :stepData="step"
          :options="availableOptions"
          :negativeOptions="negativeOptions"
          @update-step="updateStep(index, $event)"
        />
      </div>
      <div
        v-else
        style="
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <a-spin size="large" />
      </div>
      <a-button
        :disabled="
          steps.some((data) => data.key1 === null || data.key2 === null) ||
          JSON.stringify(steps) == initialSteps
        "
        @click="handleSubmit"
        >Submit</a-button
      >
    </div>
  </a-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import NegativeStepCard from './NegativeStepCard.vue';

export default {
  components: {
    NegativeStepCard,
  },
  data() {
    return {
      loading: false,
      steps: [{ id: 1, key1: null, key2: null }], // Initial step object
      initialSteps: '',
    };
  },
  computed: {
    ...mapGetters([
      'stepsToIndexMapping',
      'negativeSteps',
      'selectedTask',
      'rectificationSteps',
    ]),
    negativeOptions() {
      const optionsArray = Object.entries(this.stepsToIndexMapping)
        .filter(([key, value]) => {
          if (this.negativeSteps.includes(value)) {
            return key;
          }
        })
        .map(([label, value]) => {
          return label;
        });
      const selectedOptions = new Set();
      this.steps.forEach((step) => {
        if (step.key1) selectedOptions.add(step.key1);
        if (step.key2) selectedOptions.add(step.key2);
      });

      // Filter out selected options
      return optionsArray.filter((option) => !selectedOptions.has(option));
    },
    availableOptions() {
      // Convert the object to an array of options

      const optionsArray = Object.entries(this.stepsToIndexMapping)
        .filter(([key, value]) => {
          if (!this.negativeSteps.includes(value)) {
            return key;
          }
        })
        .map(([label, value]) => {
          return label;
        });
      const selectedOptions = new Set();
      this.steps.forEach((step) => {
        if (step.key1) selectedOptions.add(step.key1);
        if (step.key2) selectedOptions.add(step.key2);
      });

      // Filter out selected options
      return optionsArray.filter((option) => !selectedOptions.has(option));
    },
    canAddNewStep() {
      // Check if all steps have both keys filled
      return (
        this.steps.every((step) => step.key1 && step.key2) &&
        this.negativeOptions.length > 0 &&
        this.availableOptions.length > 0
      );
    },
  },
  watch: {
    rectificationSteps: {
      handler(value) {
        this.steps = value.map((data) => {
          let negativeStep = Object.entries(this.stepsToIndexMapping).find(
            ([label, value]) => value == data.negative_step && label
          );
          let rectificationStep = Object.entries(this.stepsToIndexMapping).find(
            ([label, value]) => value == data.rectification_step && label
          );
          return {
            id: data.id,
            key1: negativeStep?.length ? negativeStep[0] : null,
            key2: rectificationStep?.length ? rectificationStep[0] : null,
            update: true,
          };
        });
        this.initialSteps = JSON.stringify(this.steps);
      },
      deep: true,
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  methods: {
    ...mapActions([
      'createRectificationSteps',
      'fetchedRectificationSteps',
      'deleteRectificationStep',
    ]),
    addNewStep() {
      const newStep = {
        id: this.steps[this.steps.length - 1]?.id + 1 || 1,
        key1: null,
        key2: null,
        update: false,
      }; // Create a new step object
      this.steps.push(newStep);
    },
    async removeStep(id) {
      let step = this.steps.find((data) => data.id === id);
      this.steps = this.steps.filter((data) => data.id !== id);

      if (step.update) {
        await this.deleteRectificationStep(id);
      }
      //
    },
    updateStep(index, updatedStep) {
      this.steps.splice(index, 1, updatedStep); // Update the specific step object
    },

    async handleSubmit() {
      const negativeStepsMapping = this.steps
        .filter((data) => !data.update)
        .map((step) => {
          let payload = {
            task: this.selectedTask,
            negative_step: this.stepsToIndexMapping[step.key1],
            rectification_step: this.stepsToIndexMapping[step.key2],
          };
          this.createRectificationSteps(payload);
        });

      await Promise.all(negativeStepsMapping);

      this.steps = this.steps.map((data) => ({ ...data, update: true }));
    },
  },

  async created() {
    this.loading = true;
    let resp = await this.fetchedRectificationSteps();
    if (resp.length == 0) {
      this.addNewStep();
    }
    this.loading = false;
  },
};
</script>

<style scoped></style>
